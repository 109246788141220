import { IEntity, StringIndexedEntities } from '@utils/utils';

export interface IConstantEntity {
  name: string;
  value: any;
  baseTranslationPath?: string;
  translationTag?: string;
  translationData?: any;
}

export type ConstantsDict = StringIndexedEntities<number>;

export abstract class ConstantGroup {
  abstract list(): ConstantEntity[];

  dict(): ConstantsDict {
    const constsDict: ConstantsDict = {};
    for (const constEnt of this.list()) {
      constsDict[constEnt.name] = constEnt.value;
    }

    return constsDict;
  }

  map(): Map<any, ConstantEntity> {
    const initialMap = new Map<any, ConstantEntity>();
    return this.list().reduce((map, obj) => {
      map[obj.value] = obj;
      return map;
    }, initialMap);
  }

  public findById(value: number): ConstantEntity {
    return this.list().find((constant) => constant.value === value);
  }

  public findExtendedById = <T extends ConstantEntity>(classType: IEntity<T>, value: number): T =>
    this.findById(value) as T;

  public findByName(name: string): ConstantEntity {
    return this.list().find((constant) => constant.name === name);
  }

  public findExtendedByName = <T extends ConstantEntity>(classType: IEntity<T>, name: string): T =>
    this.findByName(name) as T;
}

export class ConstantEntity implements IConstantEntity {
  readonly name: string;
  readonly value: number;
  public baseTranslationPath: string;
  public translationTag: string;
  public translationData: string;

  // calculated
  public translationPath: string;

  constructor(obj: IConstantEntity) {
    this.name = obj.name;
    this.value = obj.value;
    this.baseTranslationPath = obj.baseTranslationPath || 'base.constants';
    this.translationData = obj.translationData;
    this.translationPath =
      this.baseTranslationPath +
      (obj.translationTag ? '.' + obj.translationTag : '') +
      (obj.baseTranslationPath ? '' : '.' + this.name);
  }

  public get lowerCaseName(): string {
    return this.name.toLowerCase();
  }

  public get nameAsCSSClass(): string {
    return this.name.replace(/\_/g, '-').toLowerCase();
  }

  public get numberValue(): number {
    return Number(this.value);
  }
}

export class ConstantIconEntity extends ConstantEntity {
  readonly icon: string = undefined;
  readonly iconNumber: string = undefined;

  constructor(obj: any) {
    super(obj as IConstantEntity);
    this.icon = obj.icon;
    this.iconNumber = obj.iconNumber;
  }
}
